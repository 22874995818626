import React from 'react';
import Box from '@mui/material/Box';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import CloseIcon from '@mui/icons-material/Close';

const defaultContextValue = {
  data: {
    // set your initial data shape here
    src: '',
    visible: false,
  },
  set: () => {},
  play: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class PlayerWrapper extends React.Component {
  constructor() {
    super();

    this.setData = this.setData.bind(this);
    this.play = this.play.bind(this);
    this.state = {
      ...defaultContextValue,
      set: this.setData,
      play: this.play,
    };
  }

  setData(newData) {
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  }

  play(src) {
    this.setData({ visible: true });
    this.player.audio.current.src = src;
    this.player.audio.current.play();
  }

  closePlayer() {
    this.player.audio.current.pause();
    this.setData({ visible: false });
  }

  onPlay(event) {
    if (typeof window !== 'undefined') {
      window.plausible('Starting an episode', {
        props: { url: event.srcElement.src },
      });
    }

    this.setData({ visible: true });
  }

  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
        <Box
          sx={{
            display: this.state.data.visible ? 'block' : 'none',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            margin: '0px auto',
            maxWidth: 700,
            width: '100%',
            bgcolor: 'rgba(255, 255, 255, 1)',
            boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.5)',
            opacity: {
              sm: 0.8,
            },
            transition: {
              sm: 'opacity .5s ease-in-out',
            },
            '&:hover': {
              opacity: {
                sm: 1,
              },
              transition: {
                sm: 'opacity .5s ease-in-out',
              },
            },
          }}
        >
          <Box
            sx={{
              textAlign: 'right',
              cursor: 'pointer',
              color: '#333',
              padding: '0.5rem 0.5rem 0 0',
            }}
          >
            <CloseIcon onClick={() => this.closePlayer()} />
          </Box>
          <Box
            component={AudioPlayer}
            sx={{
              boxShadow: 'none',
              padding: '0 0.5rem 0 0.5rem',
            }}
            ref={(c) => (this.player = c)}
            showLoopControl={false}
            showVolumeControl={false}
            progressJumpStep={10000}
            listenInterval={10000}
            src={this.state.data.src}
            onPlay={(e) => this.onPlay(e)}
          />
        </Box>
      </Provider>
    );
  }
}

export { Consumer as default, PlayerWrapper };
