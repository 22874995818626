const React = require('react');
const { PlayerWrapper } = require('./src/components/playerWrapper');

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <PlayerWrapper {...props}>{element} </PlayerWrapper>;
};

exports.onServiceWorkerUpdateReady = () => window.location.reload();
